import React from "react";
import { BsInfoCircleFill } from 'react-icons/bs';
import PageHeaderContent from "../../components/pageHeaderContent";
import { Animate } from "react-simple-animate";
import './styles.scss';
import { DiApple, DiAndroid } from 'react-icons/di'
import { FaDev, FaDatabase } from 'react-icons/fa'

const personalDetails = [
    {
        label: "Name",
        value: "Muhammad Haziq",
    },
    {
        label: "Age",
        value: "22",
    },
    {
        label: "Address",
        value: "Karachi, Pakistan",
    },
    {
        label: "Email",
        value: "muhammadhaziq.me@gmail.com",
    },
    {
        label: "Contact No",
        value: "+92 3352259495",
    },

]


const jobSummary = 'Computer Science student graduating in May 2024 with expertise in web development, blockchain, and software engineering. Skilled in languages (Java, JavaScript, PHP, C++), frameworks (Laravel, Express, NodeJS), and database management systems (MySQL, MongoDB). Key projects include an E-Commerce website and language construction project ("BIGO"). Certified in SAP and Cloud Solution Architecture. Currently leading a Final Year Project on Agriculture Supply Management using blockchain (NextJS, TailwindCSS, Node, Express, Ethereum).'

const About = () => {
    return (
      <section id="about" className="about">
        <PageHeaderContent
          headerText="About Me"
          icon={<BsInfoCircleFill size={40} />}
        />
        <div className="about__content">
          <div className="about__content__personalWrapper">
            <Animate
              play
              duration={1.5}
              delay={0}
              start={{
                transform: "translateX(-900px)",
              }}
              end={{
                transform: "translatex(0px)",
              }}
            >
              <h3>Software Developer</h3>
              <p>{jobSummary}</p>
            </Animate>
  
            <Animate
              play
              duration={1.5}
              delay={0}
              start={{
                transform: "translateX(500px)",
              }}
              end={{
                transform: "translatex(0px)",
              }}
            >
              <h3 className="personalInformationHeaderText">
                Personal Information
              </h3>
              <ul>
                {personalDetails.map((item, i) => (
                  <li key={i}>
                    <span className="title">{item.label}</span>
                    <span className="value">{item.value}</span>
                  </li>
                ))}
              </ul>
            </Animate>
          </div>
          <div className="about__content__servicesWrapper">
            <Animate
              play
              duration={1.5}
              delay={0}
              start={{
                transform: "translateX(600px)",
              }}
              end={{
                transform: "translatex(0px)",
              }}
            >
              <div className="about__content__servicesWrapper__innerContent">
                <div>
                  <FaDev size={60} color="var( --yellow-theme-main-color)" />
                </div>
                <div>
                  <DiAndroid size={60} color="var( --yellow-theme-main-color)" />
                </div>
                <div>
                  <FaDatabase size={60} color="var( --yellow-theme-main-color)" />
                </div>
                <div>
                  <DiApple size={60} color="var( --yellow-theme-main-color)" />
                </div>
              </div>
            </Animate>
          </div>
        </div>
      </section>
    );
  };
  export default About;
  


// As a laravel developer
// A degree in programming, computer science, or a related field.
// Experience working with PHP, performing unit testing, and managing APIs such as REST.
// A solid understanding of application design using Laravel.
// Knowledge of database design and querying using SQL.
// Proficiency in HTML and JavaScript.
// Practical experience using the MVC architecture.
// A portfolio of applications and programs to your name.
// Problem-solving skills and critical mindset.
// Great communication skills.
// The desire and ability to learn.