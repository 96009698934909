import React from "react";
import { Link } from 'react-router-dom';

import PageHeaderContent from "../../components/pageHeaderContent";
import { BsInfoCircleFill } from "react-icons/bs";
import ImageZero from "../../images/certificateImg/image0.png";
import ImageOne from "../../images/certificateImg/image1.png";
import ImageTwo from "../../images/certificateImg/image2.png";
import ImageThree from "../../images/certificateImg/image3.png";
import ImageFour from "../../images/certificateImg/image4.png";
import ImageFive from "../../images/certificateImg/image5.png";
import ImageSix from "../../images/certificateImg/image6.png";
import ImageSeven from "../../images/certificateImg/image7.png";

import "./styles.scss";
import { useState } from "react";

const certificationData = [
  {
    id: 2,
    name: "Certificate 1",
    image: ImageZero,
    to: 'http://youtube.com'
  },
  {
    id: 2,
    name: "Certificate 2",
    image: ImageOne,
    to: 'http://youtube.com'
  },
  {
    id: 3,
    name: "Certificate 3",
    image: ImageTwo,
    to: 'http://facebook.com'

  },
  {
    id: 3,
    name: "Certificate 4",
    image: ImageThree,
    to: 'http://google.com'
    
  },
  {
    id: 3,
    name: "Certificate 5",
    image: ImageFour,
    to: 'http://youtube.com'

  },
  {
    id: 3,
    name: "Certificate 6",
    image: ImageFive,
    to: 'http://youtube.com'

  },
  {
    id: 3,
    name: "Certificate 7",
    image: ImageSix,
    to: 'http://youtube.com'

  },
  {
    id: 2,
    name: "Certificate 8",
    image: ImageSeven,
    to: 'http://youtube.com'

  },
];

const filterData = [
  {
    filterId: 1,
    label: "All",
  },
  {
    filterId: 2,
    label: "Tech",
  },
  {
    filterId: 3,
    label: "Prof",
  },
  {
    filterId: 4,
    label: "Comp",
  },
];

const Certifications = () => {
  const [filteredvalue, setFilteredValue] = useState(1);
  const [hoveredValue, setHoveredValue] = useState(null);

  function handleFilter(currentId) {
    setFilteredValue(currentId);
  }

  function handleHover(index) {
    setHoveredValue(index);
  }

  console.log("====================================");
  console.log(hoveredValue);
  console.log("====================================");

  const filteredItems =
    filteredvalue === 1
      ? certificationData
      : certificationData.filter((item) => item.id === filteredvalue);

  console.log(filteredItems);

  return (
    <section id="certifications" className="certifications">
      <PageHeaderContent
        headerText="Certification"
        icon={<BsInfoCircleFill size={40} />}
      />
      <div className="certifications__content">
        <ul className="certifications__content__filter">
          {filterData.map((item) => (
            <li
              className={item.filterId === filteredvalue ? "active" : ""}
              onClick={() => handleFilter(item.filterId)}
              key={item.filterId}
            >
              {item.label}
            </li>
          ))}
        </ul>
        <div className="certifications__content__cards">
          {filteredItems.map((item, index) => (
            <div
              className="certifications__content__cards__item"
              key={`cardItem${item.name.trim()}`}
              onMouseEnter={() => handleHover(index)}
              onMouseLeave={() => handleHover(null)}
            >
              <div className="certifications__content__cards__item__img-wrapper">
                <a>
                  
                  <img alt="dummy data" src={item.image} />
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
export default Certifications;
