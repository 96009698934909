export const skillsData = [
    {
        label: "FRONT END",
        data: [
            {
                skillName: "REACT",
                percentage: "70",
            },
            {
                skillName: "JAVSCRIPT",
                percentage: "70",
            },
            {
                skillName: "CSS",
                percentage: "80",
            },
        ],
    },
    {
        label: "BACK END",
        data: [
            {
                skillName: "LARAVEL",
                percentage: "90",
            },
            {
                skillName: "PHP",
                percentage: "90",
            },
            {
                skillName: "WORDPRESS",
                percentage: "80",
            },
        ],
    },
    {
        label: "DATABASES",
        data: [
            {
                skillName: "MYSQL",
                percentage: "90",
            },
            {
                skillName: "MONGODB",
                percentage: "70",
            },
            {
                skillName: "ORACLE",
                percentage: "60",
            },
        ],
    },

    
]