import React from "react";
import { Link } from 'react-router-dom';

import PageHeaderContent from "../../components/pageHeaderContent";
import { BsInfoCircleFill } from "react-icons/bs";
import ImageOne from "../../images/journeyImg/image1.png";
import ImageTwo from "../../images/journeyImg/image2.png";
import ImageThree from "../../images/journeyImg/image3.png";
// import ImageFour from "../../images/journeyImg/image4.jpg";
// import ImageFive from "../../images/journeyImg/image5.jpg";
import "./styles.scss";
import { useState } from "react";

const journeyData = [
  {
    id: 2,
    name: "Ecommerce",
    image: ImageOne,
    to: 'http://youtube.com'
  },
  {
    id: 3,
    name: "Notes App",
    image: ImageTwo,
    to: 'http://facebook.com'

  },
  {
    id: 2,
    name: "Supplier Design",
    image: ImageThree,
    to: 'http://google.com'
    
  },
  // {
  //   id: 2,
  //   name: "Todo App",
  //   image: ImageFour,
  //   to: 'http://youtube.com'

  // },
  // {
  //   id: 3,
  //   name: "Shopping cart design",
  //   image: ImageFive,
  //   to: 'http://youtube.com'

  // },
];

// const filterData = [
//   {
//     filterId: 1,
//     label: "All",
//   },
//   {
//     filterId: 2,
//     label: "University",
//   },
//   {
//     filterId: 3,
//     label: "Hiher Secondary Education",
//   },
//   {
//     filterId: 4,
//     label: "Secondary Educ.",
//   },
// ];

const Journey = () => {
  const [filteredvalue, setFilteredValue] = useState(1);
  const [hoveredValue, setHoveredValue] = useState(null);

  function handleFilter(currentId) {
    setFilteredValue(currentId);
  }

  function handleHover(index) {
    setHoveredValue(index);
  }

  console.log("====================================");
  console.log(hoveredValue);
  console.log("====================================");

  const filteredItems =
    filteredvalue === 1
      ? journeyData
      : journeyData.filter((item) => item.id === filteredvalue);

  console.log(filteredItems);

  return (
    <section id="journey" className="journey">
      <PageHeaderContent
        headerText="My Journey"
        icon={<BsInfoCircleFill size={40} />}
      />
      <div className="journey__content">
        {/* <ul className="journey__content__filter">
          {filterData.map((item) => (
            <li
              className={item.filterId === filteredvalue ? "active" : ""}
              onClick={() => handleFilter(item.filterId)}
              key={item.filterId}
            >
              {item.label}
            </li>
          ))}
        </ul> */}
        <div className="journey__content__cards">
          {filteredItems.map((item, index) => (
            <div
              className="journey__content__cards__item"
              key={`cardItem${item.name.trim()}`}
              onMouseEnter={() => handleHover(index)}
              onMouseLeave={() => handleHover(null)}
            >
              <div className="journey__content__cards__item__img-wrapper">
                <a>
                  
                  <img alt="dummy data" src={item.image} />
                </a>
              </div>
             
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
export default Journey;
