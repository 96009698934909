export const data = {
    experience: [
        {
            title: "Cloud Computing",
            subTitle: "Hazza Institude",
            description:
            "IT Department Govt. of Sindh in collaboration with Hazza institude is given the Cloud Solution Architecture Certificate. In this course we worked on AWS ",
            date: "Issued: 2023",

        },
        {
            title: "Web Development",
            subTitle: "NAVTTC",
            description:
            "IT Department Govt. of Sindh in collaboration with Hazza institude is given the Cloud Solution Architecture Certificate. In this course we worked on AWS ",
            date: "Issued: 2023",

        },
    ],
    education: [
        {
            title: "Bachelor",
            subTitle: "DHA SUFFA University",
            description:
            "A final year computer science student",
            date: "2020 - 2024",
        },
        {
            title: "Intermidiate",
            subTitle: "Govt. Superior Science Collage",
            description:
            "Pre-Engineering",
            date: "2018 - 2020",
        },
        {
            title: "Matriculation",
            subTitle: "The Citizen Foundation School",
            description:
            "Computer Science",
            date: "2016 - 2018",
        },
    ],
}