import React, { useState } from "react";
import { FaBars, FaReact } from 'react-icons/fa';
import { HiX } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import './styles.scss';
import ImageOne from "../../images/haziq.png";



const data = [
    {
        label: 'HOME',
        to: '/'
    },
    {
        label: 'ABOUT',
        to: '/about'
    },
    {
        label: 'SKILLS',
        to: '/skills'
    },
    {
        label: 'RESUME',
        to: '/resume'
    },
    {
        label: 'PORTFOLIO',
        to: '/portfolio'
    },
    {
        label: 'CERTIFICATIONS',
        to: '/certifications'
    },
    {
        label: 'JOURNEY',
        to: '/journey'
    },
    {
        label: 'CONTACT',
        to: '/contact'
    },

];




const Navbar = () => {

    const [toggleIcon, setToggleIcon] = useState(false);

    const handleToggleIcon = () => {
        setToggleIcon(!toggleIcon);
    };
    return (
        <div>
            <nav className="navbar">
            <div className="navbar__container">
                    {/* Replace the FaReact component with the ImageOne component */}
                    <Link to={'/'} className="navbar__container__logo">
                        <img
                            src={ImageOne}
                            alt="Logo"
                            style={{
                                height: '50px',
                                width: '50px',
                                borderRadius: '50%', // Add border-radius for rounded corners
                            }}
                        />
                    </Link>
                </div>
                <ul className={`navbar__container__menu ${toggleIcon ? "active" : ""} `}>
                    {
                        data.map((item, key) => (
                            <li key={key} className="navbar__container__menu__item">
                                <Link className="navbar__container__menu__item__links" to={item.to}>
                                    {item.label}
                                </Link>
                            </li>
                        ))
                    }
                </ul>
                <div className="nav-icon" onClick={handleToggleIcon}>
                    {
                        toggleIcon ? <HiX size={30} /> : <FaBars size={30} />
                    }
                </div>
            </nav>
        </div>
    );
};

export default Navbar;