import React, { useRef } from 'react';
import { useState } from "react";
import emailjs from '@emailjs/browser';
import { FaWhatsapp, FaLinkedin } from 'react-icons/fa'
import { Link } from 'react-router-dom';

import PageHeaderContent from "../../components/pageHeaderContent";
import { BsInfoCircleFill } from "react-icons/bs";
import { Animate } from "react-simple-animate";
import "./styles.scss";

const Contact = () => {

  const [records, setRecords] = useState("");

  const [formValues, setFormValues] = useState({
    fullName: "",
    address: "",
    number: ""
    
  });

  //email service code
  const form = useRef();

  const sendEmail = (e) => {

    e.preventDefault();

    emailjs.sendForm('service_8bl1dn6', 'template_4aet22g', form.current, 'ns5OIIKKswXM-g_J5')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
      
    setRecords([...records, formValues]);
    setFormValues({
      
      fullName: "",
      address: "",
      number: ""
    });
    alert(
      "submitted"
    )
  };
  







  return (
    <section id="contact" className="contact">
      <PageHeaderContent
        headerText="My Contact"
        icon={<BsInfoCircleFill size={40} />}
      />
      <div className="contact__content">
        <Animate
          play
          duration={1}
          delay={0}
          start={{
            transform: "translateX(-200px)",
          }}
          end={{
            transform: "translateX(0px)",
          }}
        >
          <h3 className="contact__content__header-text">Let's Talk</h3>
        </Animate>
        <Animate
          play
          duration={1}
          delay={0}
          start={{
            transform: "translateX(200px)",
          }}
          end={{
            transform: "translateX(0px)",
          }}
        >
          <form ref={form} onSubmit={sendEmail}>
            <div className="contact__content__form">
              <div className="contact__content__form__controlswrapper">
                <div>
                  <input
                    required
                    name="from_name"
                    className="inputName"
                    type={"text"}
                    value={formValues.fullName}
                    onChange={(e) =>
                      setFormValues({ ...formValues, fullName: e.target.value })
                    }
                  />
                  <label htmlFor="from_name" className="nameLabel">
                    Name
                  </label>
                </div>
                <div>
                  <input
                    required
                    name="from_email"
                    className="inputEmail"
                    type={"email"}
                    value={formValues.address}
                    onChange={(e) =>
                      setFormValues({ ...formValues, address: e.target.value })
                    }
                  />
                  <label htmlFor="from_email" className="emailLabel">
                    Email
                  </label>
                </div>
                <div>
                  <textarea
                    required
                    name="message"
                    className="inputDescription"
                    type={"text"}
                    rows="5"
                    value={formValues.number}
                    onChange={(e) =>
                      setFormValues({ ...formValues, number: e.target.value })
                    }
                  />
                  <label htmlFor="message" className="descriptionLabel">
                    Description
                  </label>
                </div>
              </div>
              <button type="submit">Submit</button>
              
            </div>
          </form>


        </Animate>

        <Animate
          play
          duration={1}
          delay={0}
          start={{
            transform: "translateY(30px)",
          }}
          end={{
            transform: "translateY(220px)",
          }}
        >
          <div className="contact__content__connect">
            <h3>Let's Connect</h3>
            <div>
              <Link to={'https://wa.me/923053110067'}>
                <FaWhatsapp size={50} color="var( --green-theme-main-color)" />
              </Link>
              &nbsp;
              <Link to={'https://www.linkedin.com/in/muhammad-haziq-a7b397211/'}>
                <FaLinkedin size={50} color="var( --blue-theme-main-color)" />
              </Link>
            </div>
          </div>


        </Animate>

      </div>
      





    </section >

  );
};
export default Contact;

